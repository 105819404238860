import React, { useRef, useState } from 'react'
import { ThemeProvider, experimentalStyled as muiStyled } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { HyreTheme as theme } from '@hyrestaff/ambry/dist/themes/HyreTheme'
import { StyledEngineProvider } from '@material-ui/core'

const GreenButton = muiStyled(Button)({
  backgroundColor: '#00A7A4',
  textTransform: 'capitalize',
  paddingRight: '56px',
  width: 160,
})

const ArrowButton = muiStyled(Button)({
  padding: '6px',
})

const CreateEvent = ({ path, isAgencyShiftEnabled, inHouseStaffEnabled }) => {
  const anchorRef = useRef(null)
  const [toggle, setToggle] = useState(false)

  const handleEvent = () => {
    setToggle(false)
    window.open(path, '_self')
  }

  const handleAgency = () => {
    setToggle(false)
    window.dispatchEvent(new Event('openShiftCreation'))
  }

  const handleToggle = () => {
    setToggle(!toggle)
  }

  const hasVMS = inHouseStaffEnabled || isAgencyShiftEnabled

  const title = () => {
    if (inHouseStaffEnabled && isAgencyShiftEnabled) {
      return 'Agency/In-House Shift'
    }
    if (inHouseStaffEnabled) {
      return 'In-House Shift'
    }
    if (isAgencyShiftEnabled) {
      return 'Agency Shift'
    }
    return null
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid>
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="Create Event">
            <GreenButton onClick={handleToggle}>Create new</GreenButton>
            <ArrowButton color="primary" disableRipple disableFocusRipple onClick={handleToggle}>
              <ArrowDropDownIcon />
            </ArrowButton>
          </ButtonGroup>
          <Popper open={toggle} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps }) => (
              /* eslint-disable react/jsx-props-no-spreading */
              <Grow {...TransitionProps} style={{ transformOrigin: 'bottom' }}>
                <Paper style={{ width: '190px' }}>
                  <MenuList>
                    <MenuItem onClick={handleEvent}>Event</MenuItem>
                    {hasVMS && <MenuItem onClick={handleAgency}>{title()}</MenuItem>}
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default CreateEvent
