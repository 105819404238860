import React, { useState, useEffect } from 'react'
import {
  Card, CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  IconButton,
} from '@material-ui/core'
import {
  CheckCircle,
  Info as FilledCircle,
  Add as AddIcon,
  RemoveCircle as MinusIcon,
  PieChart as PieChartIcon,
} from '@material-ui/icons'
import { useCookies } from 'react-cookie'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { HyreTheme }
  from '@hyrestaff/ambry/dist/themes/HyreTheme'

import { CurrentUserHelper } from './Helper'

const HyreCardHeader = styled(CardHeader)({
  backgroundColor: (HyreTheme.palette.neutral.green),
  color: (HyreTheme.palette.neutral.main),
})

export const Scheduling = () => {
  const [tooltipOpen, settooltipOpen] = useState(false)
  const [positionTextToggle, setPositionTextToggle] = useState(true)
  const [employeeTextToggle, setEmployeeTextToggle] = useState(false)
  const [shiftCreateTextToggle, setShiftCreateTextToggle] = useState(false)
  const [publishTextToggle, setPublishTextToggle] = useState(false)
  const [departmentTextToggle, setDepartmentTextToggle] = useState(false)
  const [user, setUser] = useState(null)

  const [cookies] = useCookies(['jwt_token'])
  const token = cookies.jwt_token
  useEffect(() => {
    setUser(CurrentUserHelper({ token, setUser }))
  }, [token])
  const closeAll = () => {
    setPositionTextToggle(false)
    setEmployeeTextToggle(false)
    setShiftCreateTextToggle(false)
    setPublishTextToggle(false)
    setDepartmentTextToggle(false)
  }
  const positionText = 'Before you start scheduling, enable the positions for your business. '
  const PositionTextShow = () => (positionTextToggle ? (
    <>
      {positionText}
      <a href={`/department/${cookies.current_department_id}/positions`}>Go to positions.</a>
    </>
  ) : '')
  const employeeText = 'Add individual employees or import multiple staff using a csv template. '
  const EmployeeTextShow = () => (employeeTextToggle ? (
    <>
      {employeeText}
      <a href={`/organizers/${user.organizer.organizerId}/inhouse_staffs`}>Go to in-house staff.</a>
    </>
  ) : '')
  const shiftCreateText = 'You can either create a general shift or assign one directly to your staff. '
  const ShiftCreateTextShow = () => (shiftCreateTextToggle ? (
    <>
      {shiftCreateText}
      <a href={`/organizers/${user.organizer.organizerId}/events/new`}>Create a shift.</a>
    </>
  ) : '')

  const publishText = 'Almost there! Don’t forget to publish the shifts you’ve created. '
  const PublishTextShow = () => (publishTextToggle ? (
    <>
      {publishText}
      <a href={`/organizers/${user.organizer.organizerId}/upcoming_events`}>Publish your schedule.</a>
    </>
  ) : '')

  const departmentText = `You can add a location or department to
                          better organize your business. 
                          `
  const DepartmentTextShow = () => (departmentTextToggle ? (
    <>
      {departmentText}
      <a href="mailto:hr@hyrestaff.com">Contact Hyre to add departments.</a>
    </>
  ) : '')

  return (
    <Card>
      <HyreCardHeader
        avatar={
          <PieChartIcon />
        }
        title="Start Scheduling"
        action={(
          <IconButton onClick={() => {
            settooltipOpen(!tooltipOpen)
          }}
          >
            {tooltipOpen ? <MinusIcon /> : <AddIcon />}
          </IconButton>
        )}
      />
      <Collapse in={tooltipOpen}>
        <Divider />
        <CardContent>
          <List>
            <ListItem
              button
              onClick={() => {
                closeAll()
                setPositionTextToggle(!positionTextToggle)
              }}
            >
              <ListItemIcon>
                <CheckCircle />
              </ListItemIcon>
              <ListItemText
                primary="Add a position"
                secondary={<PositionTextShow />}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                closeAll()
                setEmployeeTextToggle(!employeeTextToggle)
              }}
            >
              <ListItemIcon>
                <FilledCircle />
              </ListItemIcon>
              <ListItemText
                primary="Add an employee"
                secondary={<EmployeeTextShow />}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                closeAll()
                setShiftCreateTextToggle(!shiftCreateTextToggle)
              }}
            >
              <ListItemIcon>
                <FilledCircle />
              </ListItemIcon>
              <ListItemText
                primary="Create a shift"
                secondary={<ShiftCreateTextShow />}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                closeAll()
                setPublishTextToggle(!publishTextToggle)
              }}
            >
              <ListItemIcon>
                <FilledCircle />
              </ListItemIcon>
              <ListItemText
                primary="Publish schedule"
                secondary={<PublishTextShow />}

              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                closeAll()
                setDepartmentTextToggle(!departmentTextToggle)
              }}
            >
              <ListItemIcon>
                <FilledCircle />
              </ListItemIcon>
              <ListItemText
                primary="Add a department"
                secondary={<DepartmentTextShow />}
              />
            </ListItem>
          </List>
        </CardContent>

      </Collapse>

    </Card>
  )
}
