import React from 'react'
import { HyreTheme } from '@hyrestaff/ambry/dist/themes/HyreTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { StyledEngineProvider } from '@material-ui/core'

import { CookiesProvider, useCookies } from 'react-cookie'
import { NavToggle } from './FacilityToggle/NavToggle'

const FacilityNavToggle = () => {
  const [cookies, setCookie] = useCookies(['jwt_token'])

  const token = cookies.jwt_token
  const client = new ApolloClient({
    uri: '/apisite/v1/graphql',
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={HyreTheme}>
        <ApolloProvider client={client}>
          <CookiesProvider>
            <NavToggle currentDepartmentId={() => cookies.current_department_id} setCookie={setCookie} />
          </CookiesProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default FacilityNavToggle
