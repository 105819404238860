import React from 'react'
import { HyreTheme }
  from '@hyrestaff/ambry/dist/themes/HyreTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { CookiesProvider } from 'react-cookie'
import { StyledEngineProvider } from '@material-ui/core'
import { BusinessTooltip }
  from './Onboarding/BusinessTooltip'

const OnboardingBusinessTooltip = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={HyreTheme}>
      <CookiesProvider>
        <BusinessTooltip />
      </CookiesProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default OnboardingBusinessTooltip
